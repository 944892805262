<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title style="width: 100%; display: block; text-align: center;" class=" align-center justify-center">
          <router-link style="display: inline-block;" to="/" class=" align-center">
            <v-img :src="require('@/assets/images/logos/logo-small.png')" max-width="130px" alt="logo" contain
              class="me-3 "></v-img>
          </router-link>
          <router-link style="display: ;" to="/" class=" align-center">
            <h2 class="text-2xl font-weight-semibold">
              SIOPAK TEGAL
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <!-- <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Adventure starts here 🚀
          </p>
          <p class="mb-2">
            Make your app management easy and fun!
          </p>
        </v-card-text> -->

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field v-model="username" outlined label="Username" placeholder="xxx" hide-details
              class="mb-3"></v-text-field>

            <v-text-field v-model="email" outlined label="Email" placeholder="xxx@xxx.com" hide-details
              class="mb-3"></v-text-field>

            <v-select v-model="role" outlined dense label="Role" hide-details :items="kelompoks"
              class="mb-3"></v-select>

            <v-select v-if="role == 'PESERTA DIDIK'" v-model="profesi" outlined hide-details dense label="Kelompok"
              :items="profesiPeserta" class="mb-3"></v-select>
            <!-- <v-select v-else-if="role == 'Pembimbing Klinik'" v-model="profesi" outlined hide-details dense
              label="Kelompok" :items="dokdiknis" class="mb-3"></v-select> -->

            <v-text-field v-model="password" outlined :type="isPasswordVisible ? 'text' : 'password'" label="Password"
              placeholder="············" :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details @click:append="isPasswordVisible = !isPasswordVisible" class="mb-3"></v-text-field>

            <v-text-field v-model="repeatPassword" outlined :type="isRepeatPasswordVisible ? 'text' : 'password'"
              label="Repeat Password" placeholder="············"
              :append-icon="isRepeatPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" hide-details
              @click:append="isRepeatPasswordVisible = !isRepeatPasswordVisible"></v-text-field>

              <VueRecaptcha :sitekey="siteKey" :load-recaptcha-script="true" @verify="handleSuccess" @error="handleError">
            </VueRecaptcha>
            <!-- <v-checkbox hide-details class="mt-1">
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">I agree to</span><a href="javascript:void(0)">privacy policy &amp; terms</a>
                </div>
              </template>
            </v-checkbox> -->

            <v-btn block color="primary" class="mt-6" @click="register()" :loading="loading">
              Register
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Sudah punya akun?
          </span>
          <router-link :to="{ name: 'login' }">
            Login
          </router-link>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { VueRecaptcha } from 'vue-recaptcha';

const apiRoot = process.env.VUE_APP_APIROOT
export default {
  components: {
    VueRecaptcha
  },
  setup() {
    const siteKey = process.env.VUE_APP_CAPTCHA_KEY
    const isPasswordVisible = ref(false)
    const isRepeatPasswordVisible = ref(false)
    const username = ref('')
    const email = ref('')
    const role = ref('')
    const profesi = ref('')
    const password = ref('')
    const repeatPassword = ref('')

    return {
      siteKey,
      isPasswordVisible,
      isRepeatPasswordVisible,
      username,
      email,
      role,
      profesi,
      password,
      repeatPassword,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data: () => ({
    loading: false,
    recaptcha: '',
    kelompoks: [
      'PESERTA DIDIK',
      'PEMBIMBING KLINIK',
      'UNIVERSITAS',
    ],
    profesiPeserta: [
      'Dokter',
      'PPDS',
      'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain',
    ],
    // dokdiknis: [
    //   'Dokter',
    //   'PPDS',
    //   'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain',
    // ],
  }),
  methods: {
    handleError(){},
    handleSuccess(response) {
      this.recaptcha = response
    },
    register() {
      this.loading = true
      const fmData = new FormData()
      fmData.append('username', this.username)
      fmData.append('email', this.email)
      fmData.append('role', this.role)
      fmData.append('profesi', this.profesi)
      fmData.append('password', this.password)
      fmData.append('repeatPassword', this.repeatPassword)
      fmData.append('recaptcha', this.recaptcha)
      axios
        .post(`${apiRoot}/Authentication/register`, fmData)
        .then(response => {
          if (response.data.code === 200) {
            alert(response.data.message)
            this.$router.push({ name: 'login' })
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
  },
}
</script>
